<template>
  <div class="header-fixed header-tablet-and-mobile-fixed toolbar-enabled aside-fixed
  aside-default-enabled"
       :data-kt-aside-minimize="asideMinimize ? 'on' : 'off'" v-if="user">

    <div class="d-flex flex-column flex-root" v-if="store && !changeStore">
      <div class="page d-flex flex-row flex-column-fluid">

        <div id="kt_aside" class="aside aside-default aside-end" :class="classMobile">
          <!--begin::Brand-->
          <div class="aside-logo flex-column-auto px-7 pt-9 pb-5"
               :class="{'justify-content-center':asideMinimize}">
            <!--begin::Logo-->
            <router-link :to="{name:'platform'}"
                         :class="{'ms-2':!asideMinimize}">
              <img alt="Logo" src="@/assets/media/logos/logo.svg" class="h-40px logo-default"/>
              <img alt="Logo" src="@/assets/media/logos/icon.svg" class="h-40px logo-minimize"/>
            </router-link>
            <!--end::Logo-->
          </div>
          <!--end::Brand-->
          <!--begin::Aside menu-->
          <div class="aside-menu flex-column-fluid px-4 px-lg-7">
            <!--begin::Aside Menu-->
            <!--begin::Menu-->
            <div class="menu menu-sub-indention menu-column menu-rounded menu-title-gray-600
            menu-icon-gray-600 menu-active-bg menu-state-primary menu-arrow-gray-500
            fw-normal text-uppercase my-5 mt-lg-4 mb-lg-0">
              <div class="me-lg-n2 pe-lg-2" id="kt_aside_menu_wrapper">

                <div class="menu-item pb-lg-5"
                     v-if="(isAdmin || user.multiple_store)  && (!asideMinimize || isMobile)">
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center bg-gray-100 w-100
                  rounded py-3 px-3 gap-3 cursor-pointer" @click="onChangeStore">
                      <div class="svg-icon svg-icon-muted svg-icon-2hx">
                        <svg width="24" height="24" viewBox="0 0 24 24"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M18 10V20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20
                        20V10H18Z" fill="currentColor"/>
                          <path opacity="0.3" d="M11 10V17H6V10H4V20C4 20.6 4.4 21 5 21H12C12.6 21
                          13 20.6 13 20V10H11Z" fill="currentColor"/>
                          <path opacity="0.3" d="M10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10H10Z"
                                fill="currentColor"/>
                          <path opacity="0.3" d="M18 10C18 11.1 17.1 12 16 12C14.9 12 14 11.1 14
                        10H18Z" fill="currentColor"/>
                          <path opacity="0.3" d="M14 4H10V10H14V4Z" fill="currentColor"/>
                          <path opacity="0.3" d="M17 4H20L22 10H18L17 4Z" fill="currentColor"/>
                          <path opacity="0.3" d="M7 4H4L2 10H6L7 4Z" fill="currentColor"/>
                          <path d="M6 10C6 11.1 5.1 12 4 12C2.9 12 2 11.1 2 10H6ZM10 10C10 11.1 10.9
                        12 12 12C13.1 12 14 11.1 14 10H10ZM18 10C18 11.1 18.9 12 20 12C21.1 12 22
                        11.1 22 10H18ZM19 2H5C4.4 2 4 2.4 4 3V4H20V3C20 2.4 19.6 2 19 2ZM12 17C12
                        16.4 11.6 16 11 16H6C5.4 16 5 16.4 5 17C5 17.6 5.4 18 6 18H11C11.6 18 12
                        17.6 12 17Z" fill="currentColor"/>
                        </svg>
                      </div>
                      <div>
                        <div class="fs-6 text-uppercase text-primary fw-boldest">
                          {{ store.name }}
                        </div>
                        <div class="fs-8 fw-normal lh-1 text-nowrap">Cambia negozio</div>
                      </div>
                    </div>
                  </div>
                </div>

                <template v-if="!isMobile">
                  <!-- HOME -->
                  <div class="menu-item">
                    <router-link :to="{name:'platform'}" class="menu-link home-link">
                      <div class="menu-icon">
                        <i class="bi bi-house fs-1"/>
                      </div>
                      <div class="menu-title">{{ $t('home') }}</div>
                    </router-link>
                  </div>
                  <!-- AGENDA -->
                  <div class="menu-item" v-if="$store.getters.hasPermissions(['agenda'])">
                    <router-link :to="{name:'agenda'}" class="menu-link">
                      <div id="nav_agenda" class="menu-icon">
                        <i class="bi bi-calendar4 fs-1 position-relative">
                          <span class="day-icon">{{ $helpers.formatDate(null,'DD') }}</span>
                        </i>
                      </div>
                      <div class="menu-title">{{ $t('agenda') }}</div>
                    </router-link>
                  </div>
                  <!-- CONTRATTI -->
                  <div class="menu-item" v-if="$store.getters.hasPermissions(['contracts'])">
                    <router-link :to="{name:'contracts'}" class="menu-link">
                      <div id="nav_contracts" class="menu-icon">
                        <i class="bi bi-journal-medical fs-1"/>
                      </div>
                      <div class="menu-title">{{ $t('contracts.title') }}</div>
                    </router-link>
                  </div>
                </template>
                <!-- REPORT -->
                <div class="menu-item" v-if="$store.getters.hasPermissions(['stats'])">
                  <router-link :to="{name:'report'}" class="menu-link">
                    <div class="menu-icon">
                      <i class="bi bi-bar-chart fs-1"/>
                    </div>
                    <div class="menu-title">{{ $t('report.title') }}</div>
                  </router-link>
                </div>

                <template v-if="$store.getters.hasPermissions(['tailoring'])">
                  <div class="menu-item pt-5">
                    <div class="menu-content">
                    <span class="fw-bold text-muted text-uppercase fs-7">
                      {{ $t('tailoring.title') }}
                    </span>
                    </div>
                  </div>
                  <!-- MODIFICHE -->
                  <div class="menu-item">
                    <router-link :to="{name:'tailoring-interventions'}" class="menu-link">
                      <div id="nav_intervention" class="menu-icon">
                        <i class="bi bi-palette2 fs-1"/>
                      </div>
                      <div class="menu-title">{{ $t('intervention.title') }}</div>
                    </router-link>
                  </div>
                </template>

                <div class="menu-item pt-5"
                     v-if="$store.getters.hasPermissions([
                       'products',
                       'supplier_orders',
                       'supplier_ddts',
                       'supplier_invoices',
                       'internal_ddts',
                       ])">
                  <div class="menu-content">
                    <span class="fw-bold text-muted text-uppercase fs-7">
                      {{ $t('warehouse') }}
                    </span>
                  </div>
                </div>
                <!-- PRODOTTI -->
                <div class="menu-item" v-if="$store.getters.hasPermissions(['products'])">
                  <router-link :to="{name:'warehouse-products'}" class="menu-link">
                    <div class="menu-icon">
                      <i class="bi bi-upc-scan fs-1"/>
                    </div>
                    <div class="menu-title">{{ $t('products.title') }}</div>
                  </router-link>
                </div>
                <!-- ORDINI AL FORNITORE -->
                <div class="menu-item" v-if="$store.getters.hasPermissions(['supplier_orders'])">
                  <router-link :to="{name:'warehouse-orders'}" class="menu-link">
                    <div class="menu-icon">
                      <i class="bi bi-boxes fs-1"/>
                    </div>
                    <div class="menu-title">{{ $t('supplier_order.title') }}</div>
                  </router-link>
                </div>
                <!-- DDT FORNITORE -->
                <div class="menu-item" v-if="$store.getters.hasPermissions(['supplier_ddts'])">
                  <router-link :to="{name:'warehouse-ddts'}" class="menu-link">
                    <div class="menu-icon">
                      <i class="bi bi-truck fs-1"/>
                    </div>
                    <div class="menu-title">{{ $t('ddt.title') }}</div>
                  </router-link>
                </div>
                <!-- FATTURE FORNITORE -->
                <div class="menu-item" v-if="$store.getters.hasPermissions(['supplier_invoices'])">
                  <router-link :to="{name:'warehouse-invoices'}" class="menu-link">
                    <div class="menu-icon">
                      <i class="bi bi-receipt-cutoff fs-2x"/>
                    </div>
                    <div class="menu-title">{{ $t('invoices.title') }}</div>
                  </router-link>
                </div>
                <!-- DDT INTERNI -->
                <div class="menu-item" v-if="$store.getters.hasPermissions(['internal_ddts'])">
                  <router-link :to="{name:'warehouse-internal-ddts'}" class="menu-link">
                    <div class="menu-icon">
                      <i class="bi bi-box-seam fs-1"/>
                    </div>
                    <div class="menu-title">{{ $t('internal_ddt.title') }}</div>
                  </router-link>
                </div>
                <!-- ORDINI DIRETTI -->
                <div class="menu-item" v-if="$store.getters.hasPermissions(['direct_orders'])">
                  <router-link :to="{name:'warehouse-direct-orders'}" class="menu-link">
                    <div class="menu-icon">
                      <i class="bi bi-bag-check fs-1"/>
                    </div>
                    <div class="menu-title">{{ $t('direct_order.title') }}</div>
                  </router-link>
                </div>

              </div>


            </div>
            <!--end::Menu-->
          </div>
          <!--end::Aside menu-->

          <div class="me-0 ms-auto d-none align-items-center d-lg-flex">
            <div
              @click="asideMinimize = !asideMinimize"
              class="btn btn-icon w-auto ps-0 me-2 me-lg-5"
              :class="{active:asideMinimize}">
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr060.svg-->
              <span class="svg-icon svg-icon-2x rotate-180">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z"
                  fill="currentColor"/>
                <path
                  d="M6.2238 13.2561C5.54282 12.5572 5.54281 11.4429 6.22379 10.7439L10.377
                6.48107C10.8779 5.96697 11.75 6.32158 11.75 7.03934V16.9607C11.75 17.6785
                10.8779 18.0331 10.377 17.519L6.2238 13.2561Z"
                  fill="currentColor"/>
                <rect opacity="0.3" x="2" y="4" width="2" height="16" rx="1"
                      fill="currentColor"/>
                </svg>
                </span>
              <!--end::Svg Icon-->
            </div>
          </div>
        </div>

        <div class="wrapper d-flex flex-column flex-row-fluid pb-20 pb-lg-0">
          <!--begin::Header-->
          <div id="header" class="header">
            <!--begin::Container-->
            <div v-if="$route.name !== 'AccessDenied'" class="container-fluid d-flex align-items-stretch justify-content-between">
              <transition name="fade">
                <div class="d-flex align-items-center" v-if="$route.name!=='platform'">
                  <button class="btn btn-icon ms-n2 me-1"
                          @click="$router.go(-1)">
                    <i class="bi bi-chevron-left fs-2x"/>
                  </button>
                </div>
              </transition>
              <!--end::Aside Toggle-->
              <!--begin::Logo bar-->
              <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
              </div>
              <!--end::Logo bar-->
              <!--begin::Topbar-->
              <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1 w-100">
                <div class="d-flex align-items-center w-100">
                  <button v-if="user.role === 'admin' && !store.is_demo && (user.is_trial || !user.subscribed)"
                          class="btn btn-light-info btn-sm"
                          @click.stop="showSubscription=true">
                    <i class="bi bi-asterisk fs-2 pe-0"/>
                    <span v-if="!isMobile" class="ps-2">Attiva il tuo abbonamento</span>
                  </button>
                </div>
                <!--begin::Toolbar wrapper-->
                <div class="d-flex align-items-stretch  gap-2 flex-shrink-0">

                  <div class="d-flex align-items-center gap-1 gap-md-2">

                    <button class="btn btn-icon btn-default rounded position-relative"
                            v-if="$store.getters.hasPermissions(['first_note'])"
                            @click.stop="rangePrimaNota()">
                      <i class="bi bi-printer fs-2qx"/>
                    </button>

                    <button type="button" class="btn btn-icon d-flex align-items-center
                    btn-default rounded" @click="$refs.searchAll.showModal()"
                            v-if="$store.getters.hasPermissions(['contracts','products'])">
                      <i class="bi bi-search m-0 fs-2qx"/>
                    </button>

                    <barcode-reader-button @setBarcode="setBarcode"
                                           customClass="btn-default rounded"/>

                    <notify-button @click="openChatList"/>

                    <button class="btn btn-icon btn-default rounded position-relative"
                            v-if="$store.getters.hasPermissions(['cart'])"
                            @click.stop="$router.push({ name: 'cart' })">
                      <i class="bi bi-bag fs-2qx mb-2"/>
                      <span class="position-absolute top-75 start-75 translate-middle
                        badge badge-circle badge-success" v-if="productsInTheCart>0">
                        {{ productsInTheCart }}
                      </span>
                    </button>
                  </div>

                  <clock v-if="!isMobile" class="mx-4"/>
                  <!--begin::User-->
                  <div class="d-flex align-items-center ms-1 ms-lg-3"
                       v-click-out-side="onClickOutsideAccount">
                    <!--begin::Menu wrapper-->
                    <div v-if="!isMobile" class="w-50px h-50px border d-flex align-items-center justify-content-center cursor-pointer rounded p-1"
                         @click.stop="showUserMenu = !showUserMenu">
                      <img class="w-100" alt="Pic" :src="store.logo_path"/>
                    </div>
                    <div v-else class="cursor-pointer"
                         @click.stop="showUserMenu = !showUserMenu">
                      <img alt="Logo" :src="store.logo_path" class="w-40px"/>
                    </div>
                    <!--begin::Menu-->
                    <div :class="{show:showUserMenu}"
                         class="menu menu-sub menu-sub-dropdown menu-column
                          menu-rounded menu-gray-800
                      menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px">
                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <div class="menu-content d-flex align-items-center px-3">
                          <!--begin::Avatar-->
                          <div class="circle-switch me-5" :class="{hasSwitch:switchEmployee}"
                               @click.stop="() => switchEmployee ? showSwitchEmployee = true : false">
                            <svg v-if="switchEmployee" fill="currentColor" viewBox="0 0 20 20">
                              <g fill-rule="evenodd" transform="translate(-446 -398)">
                                <g fill-rule="nonzero">
                                  <path stroke-width="0.01"
                                        d="M96.628 206.613A7.97 7.97 0 0 1 96 203.5a7.967 7.967 0 0 1
                              2.343-5.657A7.978 7.978 0 0 1 104 195.5a7.978 7.978 0 0 1 5.129
                              1.86.75.75 0 0 0 .962-1.15A9.479 9.479 0 0 0 104 194a9.478 9.478 0
                              0 0-6.717 2.783A9.467 9.467 0 0 0 94.5 203.5a9.47 9.47 0 0 0 .747
                              3.698.75.75 0 1 0 1.381-.585zm14.744-6.226A7.97 7.97 0 0 1 112
                              203.5a7.967 7.967 0 0 1-2.343 5.657A7.978 7.978 0 0 1 104 211.5a7.978
                              7.978 0 0 1-5.128-1.86.75.75 0 0 0-.962 1.152A9.479 9.479 0 0 0 104
                              213a9.478 9.478 0 0 0 6.717-2.783 9.467 9.467 0 0 0 2.783-6.717 9.47
                              9.47 0 0 0-.747-3.698.75.75 0 1 0-1.381.585z"
                                        transform="translate(352 204.5)"/>
                                  <path stroke-width="0.01"
                                        d="M109.5 197h-2.25a.75.75 0 1 0 0 1.5h3a.75.75 0 0 0
                                .75-.75v-3a.75.75 0 1 0-1.5 0V197zm-11 13h2.25a.75.75 0 1 0
                                0-1.5h-3a.75.75 0 0 0-.75.75v3a.75.75 0 1 0 1.5 0V210z"
                                        transform="translate(352 204.5)"/>
                                </g>
                              </g>
                            </svg>
                            <div class="symbol symbol-circle symbol-35px symbol-lg-35px">
                              <img alt="Logo" :src="user.avatar_path"/>
                            </div>
                          </div>
                          <!--end::Avatar-->
                          <!--begin::Username-->
                          <div class="d-flex flex-column" style="width: calc(100% - 50px)">
                            <div class="fw-bolder d-flex align-items-center fs-5">Ciao,
                              {{ user.first_name }}
                            </div>
                            <div
                              class="fw-bold text-muted text-hover-primary fs-7 text-truncate w-100">
                              {{ user.email }}
                            </div>
                          </div>
                          <!--end::Username-->
                        </div>
                      </div>
                      <!--end::Menu item-->
                      <!--begin::Menu separator-->
                      <div class="separator my-2"></div>
                      <!--end::Menu separator-->
                      <div class="menu-item px-5">
                        <router-link :to="{name:'profile'}" class="menu-link ps-2">
                          <div class="menu-icon">
                            <i class="bi bi-person-circle fs-1"/>
                          </div>
                          <div class="menu-title">{{ $t('buttons.profile') }}</div>
                        </router-link>
                      </div>
                      <!--end::Menu separator-->
                      <!--begin::Menu item-->
                      <template v-if="isAdmin">
                        <div class="menu-item px-5" v-if="!isMobile">
                          <router-link :to="{name:'store_settings'}" class="menu-link ps-2">
                            <div class="menu-icon">
                              <i class="bi bi-shop fs-1"/>
                            </div>
                            <div class="menu-title">{{ $t('store_settings.label') }}</div>
                          </router-link>
                        </div>
                        <div class="menu-item px-5" v-if="!isMobile">
                          <router-link :to="{name:'employees'}" class="menu-link ps-2">
                            <div class="menu-icon">
                              <i class="bi bi-people fs-1"/>
                            </div>
                            <div class="menu-title">{{ $t('employees.title') }}</div>
                          </router-link>
                        </div>
                        <div class="menu-item px-5" v-if="!isMobile">
                          <router-link :to="{name:'settings'}" class="menu-link ps-2">
                            <div class="menu-icon">
                              <i class="bi bi-gear fs-1"/>
                            </div>
                            <div class="menu-title">{{ $t('settings') }}</div>
                          </router-link>
                        </div>
                      </template>

                      <div class="menu-item px-5" v-if="$store.getters.hasPermissions(['suppliers'])">
                        <router-link :to="{name:'supplier-list'}" class="menu-link ps-2">
                          <div class="menu-icon">
                            <i class="bi bi-briefcase fs-1"/>
                          </div>
                          <div class="menu-title">{{ $t('suppliers.title') }}</div>
                        </router-link>
                      </div>

                      <!--begin::Menu separator-->
                      <div class="separator my-2"></div>
                      <!--end::Menu separator-->
                      <!--begin::Menu item-->
                      <div class="menu-item px-5">
                        <a @click="logout" class="menu-link ps-2">
                          <div class="menu-icon">
                            <i class="bi bi-power fs-1"/>
                          </div>
                          <div class="menu-title">{{ $t('auth.logout') }}</div>
                        </a>
                      </div>
                    </div>
                    <!--end::Menu-->
                    <!--end::Menu wrapper-->
                  </div>
                  <!--end::User -->

                </div>
                <!--end::Toolbar wrapper-->
              </div>
              <!--end::Topbar-->
            </div>
            <!--end::Container-->
          </div>
          <!--end::Header-->
          <!--begin::Content-->
          <div class="content fs-6 d-flex flex-column flex-column-fluid pb-20" id="content">
            <router-view/>
          </div>
          <!--end::Content-->

          <!--begin::Footer-->
          <div v-if="!isMobile" class="footer py-4 d-flex flex-lg-column">
            <!--begin::Container-->
            <div class="container-fluid d-flex flex-column flex-md-row flex-stack">
              <Credits />
            </div>
            <!--end::Container-->
          </div>
          <!--end::Footer-->
        </div>
      </div>

      <wizard-search-all ref="searchAll"/>

      <modal-switch-employers v-if="switchEmployee"
                              :show="showSwitchEmployee" @close="showSwitchEmployee = false"/>

      <div class="drawer-overlay" v-if="isMobile && mobileMenuOpen"
           @click="mobileMenuOpen = false"/>
    </div>
    <wizard-store v-else/>


    <p-d-f-reader v-if="pdfStream" :show="pdfStream !== null" :pdf="pdfStream"
                  @close="pdfStream=null"/>

    <range-dialogue ref="rangeDialogue"/>

    <teleport to="body">
      <floating-menu @clickMenu="mobileMenuOpen = !mobileMenuOpen"/>
    </teleport>

    <sidebar-component ref="sidebarChat" id-sidebar="sidebarChat">
      <template #title><h2 class="m-0">Chat</h2></template>
      <template #body>
        <chat-list/>
      </template>
    </sidebar-component>


    <subscription-modal v-if="showSubscription"
                            :show="showSubscription" @close="() => {showSubscription = false; $router.push({name:'platform'}); }"/>

    <!--layout-onboarding/-->
  </div>


</template>

<script>
import {mapGetters} from 'vuex';
import repository from '@/api/repository';

import {defineAsyncComponent} from 'vue';
import {isEmpty} from 'lodash';
import {useRoute} from "vue-router";

export default {
  name: 'PlatformLayout',
  components: {
    //LayoutOnboarding: defineAsyncComponent(() => import('@/components/onboarding/LayoutOnboarding.vue')),
    Credits: defineAsyncComponent(() => import('@/components/CreditsComponent.vue')),
    WizardStore: defineAsyncComponent(() => import('@/components/wizards/WizardStore.vue')),
    Clock: defineAsyncComponent(() => import('@/components/ClockComponent.vue')),
    ModalSwitchEmployers: defineAsyncComponent(() => import('@/components/modals/employee/switch.vue')),
    FloatingMenu: defineAsyncComponent(() => import('@/components/FloatingMenuComponent.vue')),
    WizardSearchAll: defineAsyncComponent(() => import('@/components/wizards/search/SearchAll.vue')),
    RangeDialogue: defineAsyncComponent(() => import('@/components/popup/RangeDialogue.vue')),
    NotifyButton: defineAsyncComponent(() => import('@/components/chat/NotifyButtonComponent.vue')),
    ChatList: defineAsyncComponent(() => import('@/components/chat/List/ChatListComponent.vue')),
    PDFReader: defineAsyncComponent(() => import('@/components/modals/PDFReader.vue')),
    SubscriptionModal: defineAsyncComponent(() => import('@/components/modals/plan/SubscriptionModal.vue'))
  },
  data() {
    return {
      showUserMenu: false,
      showWarehouseMenu: false,
      mobileMenuOpen: false,
      asideMinimize: true,
      showSwitchEmployee: false,
      showSubscription: false,
      pdfStream: null,
      controller: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
      isMobile: 'isMobile',
      isAdmin: 'isAdmin',
      store: 'store',
      changeStore: 'changeStore',
      employees: 'employees',
      productsInTheCart: 'cart/productsInTheCart',
    }),
    classMobile() {
      let classMobile = '';
      if (this.isMobile) {
        classMobile = 'drawer drawer-start';
        if (this.mobileMenuOpen) classMobile = 'drawer drawer-start drawer-on';
      }
      return classMobile;
    },
    switchEmployee() {
      return this.employees.length > 1 && this.isAdmin;
    },
  },
  watch: {
    user() {
      this.showSwitchEmployee = false;
    },
    $route(route) {
      this.mobileMenuOpen = false;
      this.asideMinimize = true;
      this.onClickOutsideWarehouse();
      this.onClickOutsideAccount();

      if(!this.user.is_trial && !this.user.subscribed && !this.store.is_demo){
        this.goToSubscriptionExpiredPage(route);
      }

      this.getIP().then((userIp) => {
        if (
          this.user.role !== 'admin' &&
          this.$store.getters.hasPermissions(['limited_ip'])
          && this.store.limited_ip_active
          && userIp !== this.store.limited_ip) {
          this.goToAccessDeniedPage(route);
        }
      });
    },
  },
  mounted() {
    this.emitter.on("openModalSubscribe", isOpen => {
      this.showSubscription = isOpen;
    });
    const route = useRoute();

    if(!this.user.is_trial && ! this.user.subscribed  && !this.store?.is_demo ){
      this.goToSubscriptionExpiredPage(route);
    }

    this.getIP().then((userIp) => {
      if (
        this.user.role !== 'admin' &&
        this.$store.getters.hasPermissions(['limited_ip'])
        && this.store.limited_ip_active
        && userIp !== this.store.limited_ip) {
        this.goToAccessDeniedPage(route);
      }
    });
  },
  methods: {
    async logout() {
      this.error = null;

      try {
        await this.$store.dispatch('logout')
          .then(() => {
            this.loading = false;
            this.$router.push({name: 'login'});
          });
      } catch (error) {
        this.error = error;
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    onClickOutsideWarehouse() {
      this.showWarehouseMenu = false;
    },
    onClickOutsideAccount() {
      this.showUserMenu = false;
    },
    setBarcode(val) {
      this.goToPage(val);
    },
    async goToPage(code) {
      this.$store.dispatch('setLoading', true);
      const substring = 'CTR-';

      if (this.controller) this.controller.abort();
      this.controller = new AbortController();
      const {signal} = this.controller;

      if (code.includes(substring)) {
        try {
          let number = code.replace(`#${substring}`, '');
          number = number.replace(substring,'');
          const params = {number};
          await repository.contract.getByNumber(params, signal)
            .then(({data}) => {
              if (!isEmpty(data.data)) {
                this.$router.push({
                  name: 'contracts-details',
                  params: {id: data.data.id},
                });
              } else {
                this.$swal({
                  position: 'top-end',
                  icon: 'danger',
                  title: this.$t('alert.search.contract_not_found'),
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch(() => {
              this.$swal({
                position: 'top-end',
                icon: 'danger',
                title: this.$t('alert.search.contract_not_found'),
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .finally(() => this.$store.dispatch('setLoading', false))
        } catch (error) {
          console.error(error);
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
      else {
        try {
          await repository.product.get({product_id: code}, signal)
            .then(({data}) => {
              if (!isEmpty(data.data)) {
                this.$router.push({
                  name: 'warehouse-product',
                  params: {id: code},
                });
              } else {
                this.$swal({
                  position: 'top-end',
                  icon: 'danger',
                  title: this.$t('alert.search.product_not_found'),
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch(() => {
              this.$swal({
                position: 'top-end',
                icon: 'danger',
                title: this.$t('alert.search.product_not_found'),
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .finally(() => this.$store.dispatch('setLoading', false))
        } catch (error) {
          console.error(error);
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },
    onChangeStore() {
      this.$store.commit('CHANGE_STORE', true);
    },

    async rangePrimaNota() {
      const self = this;
      if (self.$store.getters.hasPermissions(['search_first_note'])) {
        const range = await this.$refs.rangeDialogue.show({
          title: 'Stampa la prima nota',
          message: 'Seleziona un range di date per cui stampare la prima nota.',
          okButton: 'Stampa Prima Nota',
        })
        // If you throw an error, the method will terminate here unless you surround it wil try/catch
        if (range) {
          self.printPrimaNota(range);
        }
      } else {
        self.printPrimaNota();
      }
    },
    async printPrimaNota(range = null) {
      try {
        this.pdfStream = null;
        this.$store.dispatch('setLoading', true);
        await repository.store.primaNotaPDF({store_id: this.store.id, range})
          .then(({data}) => {
            this.pdfStream = window.URL.createObjectURL(data);
          })
          .finally(() => {
            this.$store.dispatch('setLoading', false);
          });
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    openChatList(){
      this.$refs.sidebarChat.onShow();
    },

    goToSubscriptionExpiredPage(route){
      if(route.name === "SubscriptionExpired") return;
      this.$router.push({
        name: 'SubscriptionExpired',
        params: { pathMatch: route.path.substring(1).split('/') },
        query: route.query,
        hash: route.hash,
      })
    },

    goToAccessDeniedPage(route){
      if(route.name === "AccessDenied") return;
      this.$router.push({
        name: 'AccessDenied',
        params: { pathMatch: route.path.substring(1).split('/') },
        query: route.query,
        hash: route.hash,
      })
    },

    async getIP() {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    }
  },

};
</script>

<style scoped lang="scss">
.menu-sub-dropdown {
  &.menu {
    &.show {
      z-index: 105;
      position: absolute;
      inset: 0 0 auto auto;
      margin: 0;
      transform: translate(-10px, 90px);
    }

    @media screen and (max-width: 767px) {
      &.show {
        transform: translate(0px, 60px);
        width: 100% !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}

.menu-item .menu-link {
  padding: 0 0.5rem;
  margin-bottom: 0.25rem;

  .menu-icon {
    margin-right: 0.75rem;
    padding: 0.65rem;
    border-radius: 0.475rem;
  }
}

.circle-switch {
  position: relative;

  & > svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    fill: var(--bs-gray-400);
    transition: transform 0.3s linear;
  }

  &.hasSwitch {
    padding: 5px;
    cursor: pointer;

    .symbol {
      border: 3px solid #fff;
    }

    &:hover {
      & > svg {
        transform: rotate(180deg);
      }
    }
  }
}

.router-link-active:not(.home-link),
.home-link.router-link-exact-active {
  color: var(--bs-primary);

  &.menu-link {
    .menu-icon {
      background: var(--bs-primary);
      box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075) !important;

      i {
        color: var(--bs-gray-100) !important;
      }
    }
  }

  .menu-title, i {
    color: var(--bs-primary);
  }

  svg [fill]:not(.permanent):not(g) {
    fill: var(--bs-primary) !important;
  }
}

.day-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 3px));
  font-size: 10px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}
</style>
