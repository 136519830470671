import api from '../api';

export default {
  periods(params) {
    return api().get('/api/report/periods', { params });
  },
  contractStatus(params) {
    return api().get('/api/report/contract-status', { params });
  },
  contractDepartments(params) {
    return api().get('/api/report/contract-departments', { params });
  },
  purchases(params) {
    return api().get('/api/report/purchases', { params });
  },
  sales(params) {
    return api().get('/api/report/sales', { params });
  },
  purchasesSales(params) {
    return api().get('/api/report/purchases-sales', { params });
  },
  suppliers(params, signal = null) {
    return api().get('/api/report/suppliers', {signal, params});
  },
  employees(params, signal = null) {
    return api().get('/api/report/employees', {signal, params});
  },
};
